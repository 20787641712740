import React from 'react';

import UnderConstruction from './pages/UnderConstruction';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <div className="App">
      <UnderConstruction />
    </div>
  );
}

export default App;
