import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ConstructionIcon from '@mui/icons-material/Construction';

export default function UnderConstruction() {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.mode === 'light'
          ? 'linear-gradient(180deg, #E3F4FF, #FFF)' // Updated to blue tones
          : 'linear-gradient(180deg, #003D66, #002B47)', // Updated to dark blue tones
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          color: theme => theme.palette.mode === 'light' ? 'primary.dark' : 'primary.light',
        }}
      >
        <ConstructionIcon sx={{ fontSize: 100, mb: 3 }} />
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
          Under Construction
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          We're working hard to bring you something awesome! Stay tuned.
        </Typography>
        <Stack direction="row" spacing={2}>
          {/* Any buttons or links can go here */}
        </Stack>
      </Container>
    </Box>
  );
}
